<template>
  <div class="content">
    <div class="detail-header">
       <div class="title-box">
          <div class="title">最高分是多少？
            <el-tag effect="dark" type="success" size="small">简单</el-tag>
          </div>
          <!--<div class="star">-->
            <!--<i class="el-icon-star-on" style="font-size:20px;vertical-align: -2px;"></i>收藏-->
          <!--</div>-->
      </div>
      <el-row class="detail-meta">
        <el-col :span="11">
          <p>分类：后端开发</p>
          <p>公司：易聘</p>
        </el-col>
        <el-col :span="8">
          <p>题型：选择题</p>
          <p>分数：10</p>
        </el-col>
        <el-col :span="5" style="text-align:right">
          <p>年份：2020</p>
          <p>推荐答题时间：15分</p>
        </el-col>
      </el-row>
      <div class="detail-tag">
          标签：<el-tag type="info" size="small">Nginx</el-tag>
      </div>
    </div>
    <!-- 内容 -->
    <div class="detail-content">
      <div class="content-box"></div>
      <!-- <el-button type="primary" size="large">查看答案</el-button> -->
      <el-collapse v-model="activeContent" @change="seeAnswer">
        <el-collapse-item title="" name="1">
          <template #title>
            <el-button type="primary" size="large">查看答案</el-button>
          </template>
          <div class="content-box"></div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-divider></el-divider>
    <div class="detail-content">
      <div class="content-box"></div>
      <el-button type="primary" size="large">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "test",
  data() {
    return {
      input1: "",
      searchlist: {},
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      value1: "",
      activeContent:""
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    seeAnswer() {
      this.$emit("add",this.defaultData)
    },
  },
};
</script>

<style scoped>
.content{
  height: calc(100vh - 167px);
}
.detail-header {
  padding:20px;
  background-color: #fff;
  border-bottom: 1px solid #EBEBF5;
  color:#80809D;
  font-size: 14px;
}
.title-box{
  line-height: 26px;
  display: flex;
  justify-content: space-between;
}
.title{
  font-size: 16px;
  color: #333;
}
.star{
  width: 120px;
  text-align: right;
}
.detail-meta p{
  margin:15px 0
}
.detail-tag .el-tag {
  border-radius: 50px;
}
/* answer */
.detail-content {
  padding: 20px;
}

.content-box {
  min-height: 250px;
  border-radius: 2px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  border: 1px dashed var(--el-border-color-base);
}
.el-button {
  background-color: #333399;
  border-color: #333399;
  width: 150px;
}
/* collapse*/

.el-collapse {
  border: none;
}
::v-deep div[role="tab"] {
  width: 150px;
}
::v-deep div[role="tab"] .el-collapse-item__header {
  border: none;
}
::v-deep div[role="tab"] .el-collapse-item__header .el-button {
  margin-bottom: 20px;
}
::v-deep div[role="tab"] .el-collapse-item__arrow {
  display: none;
}
::v-deep .el-collapse-item__wrap {
  border: none;
}
::v-deep .el-collapse-item__wrap .el-collapse-item__content {
  padding-bottom: 0;
}
::v-deep .el-collapse-item__wrap .content-box {
  margin-bottom: 0;
}
.el-divider {
  margin: 0;
  background-color: #ebebf5;
}
</style>
