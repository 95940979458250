<template>
  <div class="question">
    <!-- 头部筛选 -->
    <el-row :gutter="22">
      <el-col :span="24">
        <div class="form-header">
          <el-row>
            <el-col :span="2">
              <el-dropdown>
                <el-button type="primary" size="small">
                  筛选<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-popover placement="right" width="400" trigger="hover">
                      <div class="typeList">
                        <el-input
                          placeholder="请输入内容"
                          v-model="searchType"
                          class="input-with-select"
                        >
                          <el-button
                            slot="append"
                            icon="el-icon-search"
                          ></el-button>
                        </el-input>
                        <div class="check-box">
                          <el-checkbox-group v-model="checkList">
                            <el-checkbox label="HTML"></el-checkbox>
                            <el-checkbox label="CSS"></el-checkbox>
                            <el-checkbox label="VUE"></el-checkbox>
                          </el-checkbox-group>
                        </div>
                      </div>
                      <li slot="reference" class="type-li">
                        技能 <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-popover placement="right" width="400" trigger="hover">
                      <div class="typeList">
                        <el-input
                          placeholder="请输入内容"
                          v-model="searchType"
                          class="input-with-select"
                        >
                          <el-button
                            slot="append"
                            icon="el-icon-search"
                          ></el-button>
                        </el-input>
                        <div class="check-box">
                          <el-checkbox-group v-model="checkList">
                            <el-checkbox label="选择题"></el-checkbox>
                            <el-checkbox label="问答题"></el-checkbox>
                            <el-checkbox label="编程题"></el-checkbox>
                            <el-checkbox label="填空题"></el-checkbox>
                          </el-checkbox-group>
                        </div>
                      </div>
                      <li slot="reference" class="type-li">
                        题型 <i class="el-icon-arrow-right"></i>
                      </li>
                    </el-popover>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
            <el-col :span="21"
              >已选择：
              <el-tag type="info" size="medium" closable>选择题</el-tag>
            </el-col>
            <el-col :span="1"
              ><el-button type="text"
                ><svg-icon icon-class="close" class="close" /></el-button
            ></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="22">
      <el-col :span="5">
        <!-- 左侧列表 -->
        <div class="side-card">
          <div class="side-top">
            <h4>合计：2008题</h4>
          </div>
          <el-scrollbar style="height: 100%">
            <ul class="side-list">
              <li class="list-item">
                <el-row>
                  <el-col :span="24">
                    <div class="item-title">
                      最高分是多少?
                      <el-tag effect="dark" type="success" size="small"
                        >简单</el-tag
                      >
                    </div>
                    <div class="item-meta">
                      <el-row>
                        <el-col :span="16">
                          <p>分类：后端开发</p>
                          <p>分数：10</p>
                        </el-col>
                        <el-col :span="8">
                          <p>题型：选择题</p>
                          <p>时间：15分</p>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </li>
              <li class="list-item">
                <el-row>
                  <el-col :span="24">
                    <div class="item-title">
                      最高分是多少?
                      <el-tag effect="dark" type="success" size="small"
                        >简单</el-tag
                      >
                    </div>
                    <div class="item-meta">
                      <el-row>
                        <el-col :span="16">
                          <p>分类：后端开发</p>
                          <p>分数：10</p>
                        </el-col>
                        <el-col :span="8">
                          <p>题型：选择题</p>
                          <p>时间：15分</p>
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </el-scrollbar>
        </div>
      </el-col>
      <el-col :span="15" style="padding: 0px">
        <!-- 试题详情 -->
        <div class="detail-card">
          <el-scrollbar style="height: 100%">
            <QuestionContent />
          </el-scrollbar>
        </div>
      </el-col>
      <!-- 数据统计 -->
      <el-col :span="4">
        <div class="right-card">
          <div class="right-header">数据统计</div>
          <el-divider></el-divider>
          <div class="info">
            <p>
              <el-tooltip
                class="item"
                effect="dark"
                content="数据仅统计答题总次数"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
              答题总次数：2586
            </p>
            <p>
              <el-tooltip
                class="item"
                effect="dark"
                content="数据仅统计答题正确次数"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
              答题通过数：2386
            </p>
            <p>
              <el-tooltip
                class="item"
                effect="dark"
                content="数据仅统计答题错误次数"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
              答题失败数：200
            </p>
            <p>
              <el-tooltip
                class="item"
                effect="dark"
                content="数据仅统计答题正确最长用时"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
              答题最长时间：20分钟
            </p>
            <p>
              <el-tooltip
                class="item"
                effect="dark"
                content="数据仅统计答题正确最短用时"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
              答题最短时间：10分钟
            </p>
            <p>
              <el-tooltip
                class="item"
                effect="dark"
                content="数据仅统计答题正确中位数用时"
                placement="top-start"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
              答题中位数最短时间：13分钟
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import QuestionContent from "./content";
export default {
  name: "test",
  components: { QuestionContent },
  data() {
    return {
      input1: "",
      searchlist: {},
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      value1: "",
      searchType: "",
      checkList: [],
      checked: [],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleCheckedChange() {},
  },
};
</script>

<style scoped>
.question {
  padding: 15px;
}
.form-header {
  padding: 12px 20px;
  height: 35px;
  background-color: #fff;
  margin-bottom: 10px;
}
.el-dropdown button {
  background-color: #efefff;
  border-color: #a9a9e3;
  color: #333399;
}
.form-header .el-col {
  line-height: 35px;
}
.side-card {
  background-color: #fff;
}
.side-card .side-top {
  padding: 10px 20px;
  border-bottom: 1px solid #ebebf5;
}
.side-card .side-top h4 {
  font-size: 14px;
}
.side-card .side-list {
  height: calc(100vh - 207px);
}
.list-item {
  padding: 15px;
  height: 105px;
  border-bottom: 1px solid #ebebf5;
  color: #80809d;
  cursor: pointer;
  font-size: 14px;
}
.list-item p {
  line-height: 35px;
}
.item-title {
  font-size: 16px;
  color: #333;
  line-height: 31px;
  margin-bottom: 5px;
}
.item-tag {
  margin-top: 15px;
}
.operate {
  line-height: 100px;
}
.el-tag {
  margin-left: 20px;
}
.list-item:hover {
  background-color: #f5f5fa;
}
/* center */
.detail-card {
  background-color: #fff;
  border-radius: 2px;
}
/* right */
.right-card {
  background-color: #fff;
  border-radius: 2px;
  height: calc(100vh - 167px);
}
.right-header {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
}
.right-card .info {
  padding: 10px 20px;
}
.right-header .info .item {
  color: #515151;
}
.right-card .info p {
  line-height: 40px;
  font-size: 14px;
}

.el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-divider {
  margin: 0;
  background-color: #ebebf5;
}
</style>
